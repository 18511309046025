import { useState, useContext } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

import useAccessControl from "../../hooks/useAccessControl";
import { UIContext, UIState } from "../../providers/UIProvider";
import { BustaJob } from "../../apiCalls";
import { dispatchError } from "../../common/fx";

const ConfirmBust = ({
  address,
  disabled,
  jobId,
  title,
  fetchIntention,
}: any) => {
  const GetOptionsCatalogAccess = useAccessControl(
    "Option",
    "GetOptionsCatalog"
  );

  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [open, setOpen] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<number>(1);

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        toolTipTitle={
          disabled
            ? "Must have a submitted CO with a base plan option to change elevation."
            : undefined
        }
        disabled={disabled}
        buttonVariant={"text"}
        buttonStyle={{
          color: "black",
          paddingRight: "3rem",
        }}
        buttonText={title}
        icon={
          <AssignmentReturnIcon
            sx={{
              marginRight: "5px",
            }}
          />
        }
        maxWidth="sm"
        title={"Confirm Bust"}
        content={
          <>
            <DialogContent>
              <Box p={2}>
                <Typography variant="h6" color="text.primary">
                  Bust {address}?
                </Typography>
              </Box>
              <Box p={2}>
                <Typography variant="body2" color="text.primary">
                  "This will create and submit a change order to delete all
                  previously submitted options across all change order(s) and
                  return the job price to $0.
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Stack
                sx={{ justifyContent: "space-between" }}
                direction="row"
                spacing={1}
              >
                <Button
                  onClick={() => {
                    setOpen(false);
                    setCurrentStep(1);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>

                <Button
                  onClick={(e) =>
                    BustaJob(
                      {
                        jobId: jobId,
                      },
                      async (res: any) =>
                        await fetchIntention((coUpdate: any) => {
                          dispatch({
                            type: "Snackbar",
                            payload: {
                              show: true,
                              message: `${state.selectedJob.address} has been busted.`,
                              severity: "success",
                            },
                          });
                        }),
                      (err: any) => dispatch(dispatchError(err.response.data))
                    )
                  }
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Stack>
            </DialogActions>
          </>
        }
      />
    </>
  );
};

export default ConfirmBust;
